<template lang="pug">
div(class="cover")
  div(class="justify-content-center")
    button(
      @click.prevent="getConference"
      class="btn btn-main rounded-pill mx-1"
    ) 下一步
    img(
      v-for="(img, imgIndex) in imageList"
      :key="imgIndex"
      :src="img.src"
      class="main-bg my-2"
      alt="conferenceInfo"
    )
  //- button(
  //-     @click.prevent="getConference"
  //-     class="btn btn-main rounded-pill mx-1"
  //-   ) 下一步
</template>
<script>

export default {
  name: 'Step2',
  props: {
    identity_type: {
      type: Number
    },
    SerialNumberText: {
      type: String
    },
    conferenceData: {
      type: Object
    }
  },
  data () {
    return {
      imageList: [
        // {
        //   src: 'https://change-life-church-media.s3.ap-east-1.amazonaws.com/conference/2022+clc+conference%E8%A1%A8%E5%96%AE%E8%B3%87%E8%A8%8A%E9%A0%81%E9%9D%A2_%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F+1-2.jpg',
        // },
        // {
        //   src: 'https://change-life-church-media.s3.ap-east-1.amazonaws.com/conference/2022+clc+conference%E8%A1%A8%E5%96%AE%E8%B3%87%E8%A8%8A%E9%A0%81%E9%9D%A2-1.jpg',
        // },
        // {
        //   src: 'https://change-life-church-media.s3.ap-east-1.amazonaws.com/conference/2022+clc+conference%E8%A1%A8%E5%96%AE%E8%B3%87%E8%A8%8A%E9%A0%81%E9%9D%A2-3.jpg',
        // },
        // {
        //   src: 'https://change-life-church-media.s3.ap-east-1.amazonaws.com/conference/2022+clc+conference%E8%A1%A8%E5%96%AE%E8%B3%87%E8%A8%8A%E9%A0%81%E9%9D%A2-4.jpg',
        // },
        {
          src: 'https://change-life-church-media.s3.ap-east-1.amazonaws.com/conference/1162x292px-2022conference%E5%A0%B1%E5%90%8Dbanner%E5%9C%96.jpg'
        }
      ]
    }
  },
  methods: {
    async getConference() {
      try {
        let conferenceRes = {}
        if (this.identity_type !== 6) {
          conferenceRes = await this.$api.event.getConference({
            belongs_to: 'cf',
            clc_form_info: '',
            SerialNumberText: this.SerialNumberText
          })
        } else {
          conferenceRes = await this.$api.event.getConferenceOuter(this.conferenceData)
        }   
        if (conferenceRes.code === 400) {
          this.$emit('errorMessage', conferenceRes.message)
        }
        if (conferenceRes.code === 200) {
          this.$emit('success', conferenceRes.content)
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
