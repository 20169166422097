<template lang="pug">
div(class="desc")
  div(v-if="identity_type == 6")
    p 姓名: {{ finishData.responsible_person }}
    p 手機: {{ finishData.phone }}
  div(v-if="identity_type !== 6")
    p 小組: {{ finishData.group_name }}
    p 姓名: {{ finishData.name }}
  div(class="py-3 message")
    p(class="mb-0 fs-5 text-center success") Conference 報名成功
</template>
<script>

export default {
  name: 'Step4',
  props: {
    identity_type: {
      type: Number
    },
    finishData: {
      type: Object
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>
