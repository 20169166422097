<template lang="pug">
ValidationObserver(v-slot="{ handleSubmit }")
  form(@submit.prevent="handleSubmit(sendConference)")
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 序號
      div(class="col-lg-4 col-md-8 col-10")
        input(
          v-model="sendData.SerialNumberText"
          type="text"
          placeholder="請輸入報名序號"
          class="form-control"
          :disabled="sendData.identity_type !== 6 "
        )
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 身份
      div(class="col-lg-4 col-md-8 col-10")
        select(
          v-model="sendData.identity_type"
          class="form-select"
          disabled)
          option(value="" disabled) 請選擇您的身份
          option(
            v-for="(option, optionIndex) in identityOptions" 
            :key="optionIndex"
            :value="option.value"
          ) {{ option.text }}
    div(
      v-if="showGroupSelector"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 組別
      div(class="col-lg-4 col-md-8 col-10")
        select(
          v-model="groupType"
          class="form-select")
          option(value="" disabled) 請選擇您的組別
          option(
            v-for="(option, optionIndex) in groupTypeOptions" 
            :key="optionIndex"
            :value="option"
          ) {{ option }}
    div(
      v-if="showGroupSelector"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 牧區
      div(class="col-lg-4 col-md-8 col-10")
        select(
          v-model="districtName"
          class="form-select"
          :disabled="!groupType")
          option(value="" disabled) 請選擇您的牧區
          option(
            v-for="(option, optionIndex) in districtOptions" 
            :key="optionIndex"
            :value="option"
          ) {{ option }}
    div(
      v-if="showGroupSelector"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 小組
      div(class="col-lg-4 col-md-8 col-10")
        select(
          v-model="sendData.group_id"
          class="form-select"
          :disabled="!districtName")
          option(value="" disabled) 請選擇您的小組
          option(
            v-for="(option, optionIndex) in groupOptions" 
            :key="optionIndex"
            :value="option.group_id"
          ) {{ option.group_name }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 姓名
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="姓名" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.name"
            type="text"
            placeholder="請輸入您的姓名"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      v-if="identity_type === 7"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 介紹
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="介紹人" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.introducer"
            type="text"
            placeholder="請輸入您的介紹人"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 電話
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="電話" rules="required|digits:10" v-slot="{ errors }")
          input(
            v-model="sendData.phone"
            type="text"
            placeholder="請輸入您的電話"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      legend(class="col-2 col-form-label pt-0 px-1") 
        span(class="text-danger") *
        span 性別
      div(class="col-lg-4 col-md-8 col-10")
        div(class="form-check form-check-inline float-start")
          input(
            v-model="sendData.gender"
            class="form-check-input"
            type="radio"
            value="男"
          )
          label(
            class="form-check-label"
          ) 男性
        div(class="form-check form-check-inline float-start")
          input(
            v-model="sendData.gender"
            class="form-check-input"
            type="radio"
            value="女"
          )
          label(
            class="form-check-label"
          ) 女性
        ValidationProvider(name="性別" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.gender"
            type="hidden"
            name="性別"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      v-if="showChristianText"
      class="row mb-3 justify-content-center")
      legend(class="col-2 col-form-label pt-0 px-1") 
        span(class="text-danger") *
        span 受洗
      div(class="col-lg-4 col-md-8 col-10")
        div(class="form-check form-check-inline float-start")
          input(
            v-model="sendData.christianText"
            class="form-check-input"
            type="radio"
            value="基督徒"
          )
          label(
            class="form-check-label"
          ) 基督徒
        div(class="form-check form-check-inline float-start")
          input(
            v-model="sendData.christianText"
            class="form-check-input"
            type="radio"
            value="慕道友"
          )
          label(
            class="form-check-label"
          ) 慕道友
    div(class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 志願 1
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="志願 1" rules="required" v-slot="{ errors }")
          select(
            v-model="course1"
            class="form-select")
            option(value="") -
            option(
              v-for="(option, optionIndex) in courseOptions" 
              :key="optionIndex"
              :value="option"
              :class="optionClass(option)"
              :disabled="sendData.conferenceCourses.includes(option)"
            ) {{ option.text }}
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1")
        span(class="text-danger") *
        span 志願 2
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="志願 2" rules="required" v-slot="{ errors }")
          select(
            v-model="course2"
            class="form-select"
          )
            option(value="") -
            option(
              v-for="(option, optionIndex) in courseOptions" 
              :key="optionIndex"
              :value="option"
              :class="optionClass(option)"
              :disabled="sendData.conferenceCourses.includes(option)"
            ) {{ option.text }}
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    //- div(class="row mb-3 justify-content-center")
    //-   label(class="col-2 col-form-label")
    //-     span(class="text-danger") *
    //-     span 志願 3
    //-   div(class="col-lg-4 col-md-8 col-10")
    //-     ValidationProvider(name="志願 3" rules="required" v-slot="{ errors }")
    //-       select(
    //-         v-model="course3"
    //-         class="form-select"
    //-         :disabled="!sendData.conferenceCourses[1]")
    //-         option(value="") -
    //-         option(
    //-           v-for="(option, optionIndex) in courseOptions" 
    //-           :key="optionIndex"
    //-           :value="option"
    //-           :style="{ display: sendData.conferenceCourses.includes(option) ? 'none': 'block' }"
    //-         ) {{ option.text }}
    //-       span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    //- div(class="row mb-3 justify-content-center")
    //-   label(class="col-2 col-form-label")
    //-     span(class="text-danger") *
    //-     span 志願 4
    //-   div(class="col-lg-4 col-md-8 col-10")
    //-     ValidationProvider(name="志願 4" rules="required" v-slot="{ errors }")
    //-       select(
    //-         v-model="course4"
    //-         class="form-select"
    //-         :disabled="!sendData.conferenceCourses[2]")
    //-         option(value="") -
    //-         option(
    //-           v-for="(option, optionIndex) in courseOptions" 
    //-           :key="optionIndex"
    //-           :value="option"
    //-           :style="{ display: sendData.conferenceCourses.includes(option) ? 'none': 'block' }"
    //-         ) {{ option.text }}
    //-       span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    //- div(class="row mb-3 justify-content-center")
    //-   label(class="col-2 col-form-label")
    //-     span(class="text-danger") *
    //-     span 志願 5
    //-   div(class="col-lg-4 col-md-8 col-10")
    //-     ValidationProvider(name="志願 5" rules="required" v-slot="{ errors }")
    //-       select(
    //-         v-model="course5"
    //-         class="form-select"
    //-         :disabled="!sendData.conferenceCourses[3]")
    //-         option(value="") -
    //-         option(
    //-           v-for="(option, optionIndex) in courseOptions" 
    //-           :key="optionIndex"
    //-           :value="option"
    //-           :style="{ display: sendData.conferenceCourses.includes(option) ? 'none': 'block' }"
    //-         ) {{ option.text }}
    //-       span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 備註
      div(class="col-lg-4 col-md-8 col-10")
        textarea(
          v-model="sendData.memo"
          type="text"
          class="form-control"
          maxlength="300"
        )
    div(class="justify-content-center")
      button(
        class="btn btn-main rounded-pill mx-1"
        type="submit"
      ) 完成填寫
</template>
<script>

export default {
  name: 'Step3',
  props: {
    conferenceData: {
      type: Object
    },
    SerialNumberText: {
      type: String
    },
    identity_type: {
      type: Number
    },
  },
  data () {
    return {
      identityOptions: [],
      groupTypeOptions: [],
      groups: [],
      courseOptions: [],
      groupType: '',
      districtName: '',
      course1: this.conferenceData.conferenceCourses[0],
      course2: this.conferenceData.conferenceCourses[1],
      // course3: '',
      // course4: '',
      // course5: '',
      memo: '',
      sendData: {
        belongs_to: 'cf',
        group_id: this.conferenceData.group_id,
        name: this.conferenceData.name,
        phone: this.conferenceData.phone,
        email: '',
        gender: this.identity_type === 4 ? '女' : this.conferenceData.gender || '',
        identity_type: this.identity_type,
        memo: '',
        church:'',
        introducer: '',
        SerialNumberText: this.SerialNumberText,
        christianText: this.conferenceData.christianText || '基督徒',
        conferenceCourses: this.conferenceData.conferenceCourses || []
      }
    }
  },
  watch: {
    groupType (val) {
      if (val)  {
        this.districtName = ''
        this.sendData.group_id = ''
      }
    },
    districtName (val) {
      if (val === '')  this.sendData.group_id = ''
    },
    course1 (val) {
      this.sendData.conferenceCourses[0] = val
    },
    course2 (val) {
      this.sendData.conferenceCourses[1] = val
    },
    // course3 (val) {
    //   this.sendData.conferenceCourses[2] = val
    // },
    // course4 (val) {
    //   this.sendData.conferenceCourses[3] = val
    // },
    // course5 (val) {
    //   this.sendData.conferenceCourses[4] = val
    // }
  },
  computed: {
    districtOptions () {
      const groupMap = this.groups
        .filter(group => group.group_type_name === this.groupType)
        .reduce((acc, g) => {
          acc[g.district_name] = true
          return acc
        }, {})
      return Object.keys(groupMap).map(g => {return g})
    },
    groupOptions () {
      return this.groups
        .filter(g => g.district_name === this.districtName)
        .map(g => ({ group_name: g.group_name, group_id: g.group_id }))
    },
    showChristianText () {
      // 8:會友(已參加小組)、9:會友(未參加小組)、7:新朋友(第一次參加)
      return [8,9,7].includes(this.identity_type)
    },
    showGroupSelector () {
      // 8:會友(已參加小組)、7:新朋友(第一次參加)、2:全職同工或傳道、3:牧師、 4:師母、5:長老
      return [8,7,2,3,4,5].includes(this.identity_type)
    }
  },
  methods: {
    optionClass (option) {
      if (this.sendData.conferenceCourses.includes(option)) {
        return 'd-none'
      } else {
        return 'd-block'
      }
    },
    async getIdentityType () {
      try {
        let identityRes = await this.$api.event.getIdentityType()
        this.identityOptions = identityRes.content
      } catch (err) {
        console.log(err)
      }
    },
    async getClcGroupSelect () {
      try {
        let groupRes = await this.$api.event.getClcGroupSelect()
        this.groupTypeOptions = groupRes.content.group_type
        this.groups = groupRes.content.group
        let selectedGroup = groupRes.content.group.find((item) => { return item.group_id === this.conferenceData.group_id })
        this.groupType = selectedGroup ? selectedGroup.group_type_name : ''
        this.districtName = selectedGroup ? selectedGroup.district_name : ''
      } catch (err) {
        console.log(err)
      }
    },
    async getConferenceCourse () {
      try {
        let courseRes = await this.$api.event.getConferenceCourse()
        this.courseOptions = courseRes.content
      } catch (err) {
        console.log(err)
      }
    },
    async sendConference () {
      try {
        let sendRes = await this.$api.event.sendConference(this.sendData)
        if (sendRes.code === 400) {
          this.$emit('errorMessage', sendRes.message)
        }
        if (sendRes.code === 200) {
          this.$emit('success', sendRes.content)
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
  created () {
    this.getIdentityType()
    this.getClcGroupSelect()
    this.getConferenceCourse()
  }
}
</script>
