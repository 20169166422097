<template lang="pug">
div(id="app")
  div(class="header")
    img(
      class="clc-logo"
      src="./assets/logo.png"
    )
  div(
    v-if="!errorMessage"
    class="container pb-5")
    div(v-if="step === 0")
      img(
        class="main-bg my-4"
        alt="title"
        src="https://change-life-church-media.s3.ap-east-1.amazonaws.com/conference/1162x292px-2022conference%E5%A0%B1%E5%90%8Dbanner%E5%9C%96.jpg")
      Step0(
        @success="handleCheckSuccess"
        @errorMessage="handleCheckError"
        @stepOuter="handleStepOuter")
    div(
      v-if="step === 'outer'"
      class="my-4"
    )
      StepOuter0(
        @success="handleCheckOuterSuccess"
        @errorMessage="handleCheckError"
      )
    div(
      v-if="step === 1"
      class="my-4"
    )
      Step1(
        :identity_type="identity_type"
        @agree="step = 2"
      )
    div(
      v-if="step === 2"
      class="my-4"
    )
      Step2(
        :identity_type="identity_type"
        :SerialNumberText="SerialNumberText"
        :conferenceData="conferenceData"
        @success="handleSuccessToGetConference"
        @errorMessage="handleCheckError"
      )
    div(
      v-if="step === 3"
      class="my-4"
    )
      Step3(
        v-if="identity_type !== 6"
        :identity_type="identity_type"
        :SerialNumberText="SerialNumberText"
        :conferenceData="conferenceData"
        @success="handleSuccessToSendConference"
        @errorMessage="handleCheckError"
      )
      StepOuter3(
        v-if="identity_type === 6"
        :outerData="outerData"
        :conferenceData="conferenceData"
        @success="handleSuccessToSendConference"
        @errorMessage="handleCheckError"
      )
    div(
      v-if="step === 'finish'"
      class="my-4"
    )
      Finish(
        :identity_type="identity_type"
        :finishData="finishData"
      )
  div(
    v-if="errorMessage"
    class="container")
    div(
      class="my-4 py-3 message")
      p(class="mb-0 fs-5 text-center text-danger") {{ errorMessage }}
    button(
      @click="handleBack"
      class="btn btn-main rounded-pill mx-1"
    ) 上一步
</template>
<script>
import Step0 from './components/step0'
import StepOuter0 from './components/stepOuter0'
import Step1 from './components/step1'
import Step2 from './components/step2'
import Step3 from './components/step3'
import StepOuter3 from './components/stepOuter3'
import Finish from './components/finish'

export default {
  name: 'App',
  components: {
    Step0,
    StepOuter0,
    Step1,
    Step2,
    Step3,
    StepOuter3,
    Finish
  },
  data () {
    return {
      isLoading: false,
      step: 0,
      identity_type: null,
      SerialNumberText: null,
      conferenceData: {},
      finishData: {},
      errorMessage: null,
      outerData: {}
    }
  },
  methods: {
    handleCheckSuccess (val) {
      this.step = 1
      this.identity_type = val.identity_type
      this.SerialNumberText = val.SerialNumberText
    },
    handleCheckOuterSuccess (val) {
      this.conferenceData = val
      this.outerData = val
      this.step = 1
    },
    handleStepOuter (val) {
      this.step = 'outer'
      this.identity_type = val
    },
    handleSuccessToGetConference (val) {
      this.step = 3
      this.conferenceData = val
    },
    handleSuccessToSendConference (val) {
      this.step= 'finish'
      this.finishData = val
    },
    handleCheckError (val) {
      this.errorMessage = val
    },
    handleBack() {
      this.errorMessage = null
    }
  }
}
</script>
