<template lang="pug">
div
  form
    div(class="row mb-3 justify-content-center")
      label(class="col-auto col-form-label px-1") 身份
      div(class="col-lg-4 col-md-8 col-10")
        select(
          v-model="identity_type"
          class="form-select")
          option(value="" disabled) 請選擇報名身份
          option(
            v-for="(option, optionIndex) in identityOptions" 
            :key="optionIndex"
            :value="option.value"
          ) {{ option.text }}
    div(
      v-if="showSerialNumberText"
      class="row mb-3 justify-content-center")
      label(class="col-auto col-form-label px-1") 序號
      div(class="col-lg-4 col-md-8 col-10")
        input(
          v-model="SerialNumberText"
          type="text"
          placeholder="請輸入報名序號"
          class="form-control"
        )
    div(class="justify-content-center")
      button(
        v-if="showSerialNumberText"
        @click.prevent="checkSerialNumberText"
        class="btn btn-main rounded-pill mx-1"
      ) 我已繳費
      button(
        v-if="!showSerialNumberText"
        @click.prevent="noneSerialNumberText"
        class="btn btn-main rounded-pill mx-1"
      ) 我已繳費
</template>
<script>

export default {
  name: 'Step0',
  components: {
  },
  data () {
    return {
      showSerialNumberText: true,
      identityOptions: [],
      identity_type: '',
      SerialNumberText: '',
    }
  },
  watch: {
    identity_type(val) {
      if (val !== 6) {
        // 本堂會友
        this.showSerialNumberText = true
      } else {
        // 非本堂會友
        this.showSerialNumberText = false
      }
    }
  },
  methods: {
    async getIdentityType () {
      try {
        let identityRes = await this.$api.event.getIdentityType()
        this.identityOptions = identityRes.content
      } catch (err) {
        console.log(err)
      }
    },
    async checkSerialNumberText () {
      if (this.identity_type !=='') {
        try {
          let checkRes = await this.$api.event.checkSerialNumberText({ SerialNumberText: this.SerialNumberText })
          if (checkRes.code === 400) {
            this.$emit('errorMessage', checkRes.message)
          }
          if (checkRes.code === 200) {
            this.$emit('success', { identity_type: this.identity_type, SerialNumberText: this.SerialNumberText })
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.$swal({
          icon: 'warning',
          text: '請選擇報名身份',
          type: 'error',
          showConfirmButton: '#008087',
        })
      }
    },
    noneSerialNumberText () {
      if (this.identity_type !=='') {
        this.$emit('stepOuter', this.identity_type)
      } else {
        this.$swal({
          icon: 'warning',
          text: '請選擇報名身份',
          type: 'error',
          showConfirmButton: '#008087',
        })
      }
    }
  },
  created () {
    this.getIdentityType()
  }
}
</script>
