<template lang="pug">
ValidationObserver(v-slot="{ handleSubmit }")
  form(@submit.prevent="handleSubmit(sendConferenceOuter)")
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span 統一編號
      div(class="col-lg-4 col-8")
        ValidationProvider(name="編一編號" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.uniformNo"
            type="text"
            placeholder="請輸入編一編號"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span 統編抬頭
      div(class="col-lg-4 col-8")
        ValidationProvider(name="統編抬頭" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.uniformTitle"
            type="text"
            placeholder="請輸入統編抬頭"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}  
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span 所屬教會
      div(class="col-lg-4 col-8")
        ValidationProvider(name="所屬教會" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.church"
            type="text"
            placeholder="請輸入所屬教會"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span 主要連絡人
      div(class="col-lg-4 col-8")
        ValidationProvider(name="主要連絡人" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.responsible_person"
            type="text"
            placeholder="請輸入主要連絡人"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span 手機
      div(class="col-lg-4 col-8")
        ValidationProvider(name="手機" rules="required|digits:10" v-slot="{ errors }")
          input(
            v-model="sendData.phone"
            type="text"
            placeholder="請輸入負責人手機"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span Email
      div(class="col-lg-4 col-8")
        ValidationProvider(name="Email" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.email"
            type="text"
            placeholder="請輸入負責人 Email"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      legend(class="col-md-2 col-3 col-form-label pt-0 px-1") 
        span(class="text-danger") *
        span 性別
      div(class="col-lg-4 col-8")
        div(class="form-check form-check-inline float-start")
          input(
            v-model="sendData.gender"
            class="form-check-input"
            type="radio"
            value="男"
          )
          label(
            class="form-check-label"
          ) 男性
        div(class="form-check form-check-inline float-start")
          input(
            v-model="sendData.gender"
            class="form-check-input"
            type="radio"
            value="女"
          )
          label(
            class="form-check-label"
          ) 女性
        ValidationProvider(name="性別" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.gender"
            type="hidden"
            name="性別"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1")
        span(class="text-danger") *
        span 教會電話
      div(class="col-lg-4 col-8")
        ValidationProvider(name="教會電話" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.tel"
            type="text"
            placeholder="請輸入教會電話"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span 教會職稱
      div(class="col-lg-4 col-8")
        ValidationProvider(name="教會職稱" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.job_title"
            type="text"
            placeholder="請輸入教會職稱"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span 報名人數
      div(class="col-lg-4 col-8")
        ValidationProvider(name="報名人數" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.registration_number"
            type="number"
            placeholder="請輸入報名人數"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-md-2 col-3 col-form-label px-1") 
        span(class="text-danger") *
        span 成員姓名
      div(class="col-lg-4 col-8")
        ValidationProvider(name="成員姓名" rules="required" v-slot="{ errors }")
          textarea(
            v-model="sendData.registration_name_list"
            type="text"
            class="form-control"
            maxlength="2000"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(class="justify-content-center")
      button(
        class="btn btn-main rounded-pill mx-1"
        type="submit"
      ) 完成填寫
</template>
<script>

export default {
  name: 'Step3',
  props: {
    conferenceData: {
      type: Object
    },
    outerData: {
      type: Object
    }
  },
  data () {
    return {
      sendData: {
        belongs_to: 'cf',
        church: '',
        gender: '',
        responsible_person: '',
        phone: '',
        tel: '',
        job_title: '',
        registration_number: '',
        registration_name_list: '',
        account_5: this.outerData.account5,
        remittance_date: this.$dayjs(this.conferenceData.remittanceDate).format('YYYY-MM-DD'),
        email: '',
        uniformNo: '',
        uniformTitle: ''
      }
    }
  },
  methods: {
    async sendConferenceOuter () {
      try {
        let sendRes = await this.$api.event.sendConferenceOuter(this.sendData)
        if (sendRes.code === 400) {
          this.$emit('errorMessage', sendRes.message)
        }
        if (sendRes.code === 200) {
          this.$emit('success', sendRes.content)
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
  created () {
  }
}
</script>
