<template lang="pug">
div(class="desc")
  div(v-html="privacy")
  div(class="form-check agreeSign my-4 mx-auto")
    input(
      v-model="agreeSign"
      class="form-check-input"
      type="checkbox")
    label(class="form-check-label") 【←點選】我同意簽署
  div(class="justify-content-center")
    button(
      v-if="agreeSign"
      @click.prevent="agree"
      class="btn btn-main rounded-pill mx-1"
    ) 下一步
</template>
<script>

export default {
  name: 'Step1',
  props: {
    identity_type: {
      type: Number
    }
  },
  data () {
    return {
      agreeSign: false,
      desc: '',
      privacy: '',
      title: ''
    }
  },
  methods: {
    async getConferenceDesc () {
      try {
        let descRes = await this.$api.event.getConferenceDesc({
          belongs_to: 'cf',
          clc_form_info: '',
          identity_type: this.identity_type
        })
        this.desc = descRes.content.event_desc
        this.privacy = descRes.content.privacy_sign_desc
        this.title = descRes.content.event_title
      } catch (err) {
        console.log(err)
      }
    },
    agree () {
      this.$emit('agree')
    },
  },
  created () {
    this.getConferenceDesc()
  }
}
</script>
