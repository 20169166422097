import Vue from 'vue'
import App from './App.vue'
import './style/style.css'
import api from './api'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import tw from 'vee-validate/dist/locale/zh_TW.json'
import * as rules from 'vee-validate/dist/rules'
import VueSweetalert2 from 'vue-sweetalert2'
import Dayjs from 'vue-dayjs';
import 'sweetalert2/dist/sweetalert2.min.css';

document.title = 'Conference' 
Vue.config.productionTip = false
Vue.prototype.$api = api

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('tw', tw);
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.config.productionTip = false

Vue.use(VueSweetalert2)
Vue.use(Dayjs);

new Vue({
  render: h => h(App),
}).$mount('#app')
