var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendConferenceOuter)}}},[_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("統一編號")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"編一編號","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.uniformNo),expression:"sendData.uniformNo"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"請輸入編一編號"},domProps:{"value":(_vm.sendData.uniformNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "uniformNo", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("統編抬頭")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"統編抬頭","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.uniformTitle),expression:"sendData.uniformTitle"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"請輸入統編抬頭"},domProps:{"value":(_vm.sendData.uniformTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "uniformTitle", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("所屬教會")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"所屬教會","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.church),expression:"sendData.church"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"請輸入所屬教會"},domProps:{"value":(_vm.sendData.church)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "church", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("主要連絡人")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"主要連絡人","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.responsible_person),expression:"sendData.responsible_person"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"請輸入主要連絡人"},domProps:{"value":(_vm.sendData.responsible_person)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "responsible_person", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("手機")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"手機","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.phone),expression:"sendData.phone"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"請輸入負責人手機"},domProps:{"value":(_vm.sendData.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "phone", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("Email")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.email),expression:"sendData.email"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"請輸入負責人 Email"},domProps:{"value":(_vm.sendData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "email", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('legend',{staticClass:"col-md-2 col-3 col-form-label pt-0 px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("性別")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('div',{staticClass:"form-check form-check-inline float-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.gender),expression:"sendData.gender"}],staticClass:"form-check-input",attrs:{"type":"radio","value":"男"},domProps:{"checked":_vm._q(_vm.sendData.gender,"男")},on:{"change":function($event){return _vm.$set(_vm.sendData, "gender", "男")}}}),_c('label',{staticClass:"form-check-label"},[_vm._v("男性")])]),_c('div',{staticClass:"form-check form-check-inline float-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.gender),expression:"sendData.gender"}],staticClass:"form-check-input",attrs:{"type":"radio","value":"女"},domProps:{"checked":_vm._q(_vm.sendData.gender,"女")},on:{"change":function($event){return _vm.$set(_vm.sendData, "gender", "女")}}}),_c('label',{staticClass:"form-check-label"},[_vm._v("女性")])]),_c('ValidationProvider',{attrs:{"name":"性別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.gender),expression:"sendData.gender"}],attrs:{"type":"hidden","name":"性別"},domProps:{"value":(_vm.sendData.gender)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "gender", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("教會電話")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"教會電話","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.tel),expression:"sendData.tel"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"請輸入教會電話"},domProps:{"value":(_vm.sendData.tel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "tel", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("教會職稱")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"教會職稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.job_title),expression:"sendData.job_title"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"請輸入教會職稱"},domProps:{"value":(_vm.sendData.job_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "job_title", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("報名人數")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"報名人數","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.registration_number),expression:"sendData.registration_number"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"請輸入報名人數"},domProps:{"value":(_vm.sendData.registration_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "registration_number", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-3 justify-content-center"},[_c('label',{staticClass:"col-md-2 col-3 col-form-label px-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('span',[_vm._v("成員姓名")])]),_c('div',{staticClass:"col-lg-4 col-8"},[_c('ValidationProvider',{attrs:{"name":"成員姓名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendData.registration_name_list),expression:"sendData.registration_name_list"}],staticClass:"form-control",attrs:{"type":"text","maxlength":"2000"},domProps:{"value":(_vm.sendData.registration_name_list)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendData, "registration_name_list", $event.target.value)}}}),_c('span',{staticClass:"d-block text-start fst-italic text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"justify-content-center"},[_c('button',{staticClass:"btn btn-main rounded-pill mx-1",attrs:{"type":"submit"}},[_vm._v("完成填寫")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }