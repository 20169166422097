<template lang="pug">
ValidationObserver(v-slot="{ handleSubmit }")
  form(@submit.prevent="handleSubmit(checkOuterIfPay)")
    div(class="row mb-3 justify-content-center")
      label(class="col-auto col-form-label px-1") 匯款帳號
      div(class="col-lg-4 col-8")
        ValidationProvider(name="匯款帳號" rules="required|digits:5" v-slot="{ errors }")
          input(
            v-model="outerData.account5"
            type="text"
            placeholder="請輸入匯款帳號"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-auto col-form-label px-1") 匯款日期
      div(class="col-lg-4 col-8")
        input(
          v-model="outerData.remittanceDate"
          type="date"
          placeholder="請選擇匯款日期"
          class="form-control"
        )
    div(class="justify-content-center")
      button(
        class="btn btn-main rounded-pill mx-1"
        type="submit"
      ) 我已匯款
</template>
<script>

export default {
  name: 'StepOuter',
  data () {
    return {
      outerData: {
        belongs_to: 'cf',
        clc_form_info: '',
        account5: '',
        remittanceDate: this.$dayjs().format('YYYY-MM-DD'),
      }
    }
  },
  methods: {
    async checkOuterIfPay () {
      try {
        let checkRes = await this.$api.event.getConferenceOuter(this.outerData)
        if (checkRes.code === 400) {
          this.$emit('errorMessage', checkRes.message)
        }
        if (checkRes.code === 200) {
          this.$emit('success', this.outerData)
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
}
</script>
