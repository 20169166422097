import BaseAPI from './base'

const eventAPI = {
  getIdentityType (data) { return BaseAPI('get', `/Conference/GetIdentityType`, data) },
  getClcGroupSelect (data) { return BaseAPI('get', `/Conference/GetClcGroupSelect`, data) },
  getConferenceCourse (data) { return BaseAPI('get', `/Conference/GetConferenceCourse`, data,) },
  getConferenceDesc (data) { return BaseAPI('post', `/Conference/GetConferenceDesc`, data) },
  checkSerialNumberText (data) { return BaseAPI('post', `/Conference/CheckSerialNumberText`, data) },

  getConference (data) { return BaseAPI('post', `/Conference/GetConference`, data) },
  sendConference (data) { return BaseAPI('post', `/Conference/SendConference`, data) },

  getConferenceOuter (data) { return BaseAPI('post', `/Conference/GetConferenceOuter`, data) },
  sendConferenceOuter (data) { return BaseAPI('post', `/Conference/SendConferenceOuter`, data) }
}
export default eventAPI
